import React from 'react';

function FAQ() {
    return (
        <div>
            <h1>Under contruction</h1>
        </div>
    )
}

export default FAQ;